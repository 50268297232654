// extracted by mini-css-extract-plugin
export var author = "Comments-module--author--2ff48";
export var avatar = "Comments-module--avatar--0d95e";
export var body = "Comments-module--body--e0c78";
export var callToFeedback = "Comments-module--callToFeedback--2bb79";
export var comment = "Comments-module--comment--42b42";
export var comments = "Comments-module--comments--18b31";
export var date = "Comments-module--date--11adb";
export var footer = "Comments-module--footer--75667";
export var helper = "Comments-module--helper--40723";
export var name = "Comments-module--name--791fa";